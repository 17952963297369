// import locales from "../../config/i18n"

export function fileNameByUrl(url) {
  const decoded = decodeURIComponent(url)
  return decoded.substring(decoded.lastIndexOf("/") + 1)
}

/**
 * Shuffles array in place. ES6 version
 * @param {Array} a items An array containing the items.
 */
export function shuffle(a) {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    ;[a[i], a[j]] = [a[j], a[i]]
  }
  return a
}

/**
 * Returns a random number between min (inclusive) and max (exclusive)
 */
export function getRandomNumber(min, max) {
  return Math.random() * (max - min) + min
}

/**
 * Returns a random integer between min (inclusive) and max (inclusive).
 * The value is no lower than min (or the next integer greater than min
 * if min isn't an integer) and no greater than max (or the next integer
 * lower than max if max isn't an integer).
 * Using Math.round() will give you a non-uniform distribution!
 */
export function getRandomInt(min, max) {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min + 1)) + min
}

export const _pipeToTab = raw => {
  return raw.replace(/\|/g, '<span class="tab"></span>')
}
export function _linkResolver(doc) {
  switch (doc.type) {
    case "home":
      return `/`

    default:
      return `/${doc.uid}`
  }
}

// export const _localizeText = (text) => {
//   const locale = "fr-fr";

//   return locales[locale] && locales[locale][text]
//     ? locales[locale][text]
//     : text;
// };
