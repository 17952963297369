import React from "react"
import Helmet from "react-helmet"
import PropTypes from "prop-types"
import Facebook from "./Facebook"
import Twitter from "./Twitter"
import { useSiteMetadata } from "../../hooks/useSiteMetadata"

const Seo = ({
  pageTitle,
  pageDescription,
  pageBanner,
  pathname,
  page,
  template,
}) => {
  const { buildTime, site, global } = useSiteMetadata()

  const { siteUrl, defaultBanner, author, twitter, facebook, ogLanguage } = site

  // console.log(siteUrl, defaultBanner, ogLanguage, author, twitter, facebook)
  const { default_title, default_description, default_image } = global

  const homeURL = `${siteUrl}`

  const seo = {
    title: page ? pageTitle + " - " + default_title.text : default_title.text,
    description: page ? pageDescription : default_description.text,
    image: pageBanner ? pageBanner : default_image.url,
    url: `${siteUrl}${pathname || ""}`,
  }
  // console.log(seo)
  // schema.org in JSONLD format
  // https://developers.google.com/search/docs/guides/intro-structured-data
  // You can fill out the 'author', 'creator' with more data or another type (e.g. 'Organization')

  // return null
  const schemaOrgWebPage = {
    "@context": "http://schema.org",
    "@type": "WebPage",
    url: homeURL,
    //headline,
    //inLanguage: siteLanguage,
    mainEntityOfPage: homeURL,
    description: seo.description,
    name: seo.title,
    author: {
      "@type": "Person",
      name: author,
    },
    copyrightHolder: {
      "@type": "Person",
      name: author,
    },
    copyrightYear: "2019",
    creator: {
      "@type": "Person",
      name: author,
    },
    publisher: {
      "@type": "Person",
      name: author,
    },
    //datePublished: '2019-01-18T10:30:00+01:00',
    dateModified: buildTime,
    image: {
      "@type": "ImageObject",
      url: `${siteUrl}${defaultBanner}`,
    },
  }

  let schemaArticle = null

  if (page) {
    schemaArticle = {
      "@context": "http://schema.org",
      "@type": "Article",
      author: {
        "@type": "Person",
        name: author,
      },
      copyrightHolder: {
        "@type": "Person",
        name: author,
      },
      copyrightYear: "2019",
      creator: {
        "@type": "Person",
        name: author,
      },
      publisher: {
        "@type": "Organization",
        name: author,
        logo: {
          "@type": "ImageObject",
          url: `${siteUrl}${defaultBanner}`,
        },
      },
      //datePublished: node.first_publication_date,
      //dateModified: node.last_publication_date,
      description: seo.description,
      headline: seo.title,
      //inLanguage: 'en',
      url: seo.url,
      name: seo.title,
      image: {
        "@type": "ImageObject",
        url: seo.image,
      },
      mainEntityOfPage: seo.url,
    }
  }

  return (
    <>
      <Helmet title={seo.title}>
        <html lang={ogLanguage} />
        <meta
          name="google-site-verification"
          content="3NHqfd3NH9E-EHrm-yOEvIwaNuFnSus0NT2eNGrV2J8"
        />
        <meta name="description" content={seo.description} />
        <meta name="image" content={seo.image} />
        <meta name="gatsby-starter" content="Gatsby Starter Prismic i18n" />
        {!page && (
          <script type="application/ld+json">
            {JSON.stringify(schemaOrgWebPage)}
          </script>
        )}
        {page && (
          <script type="application/ld+json">
            {JSON.stringify(schemaArticle)}
          </script>
        )}
        <body className={template} />
      </Helmet>
      <Facebook
        desc={seo.description}
        image={seo.image}
        title={seo.title}
        type={page ? "article" : "website"}
        url={seo.url}
        locale={ogLanguage}
        name={facebook}
      />
      <Twitter
        title={seo.title}
        image={seo.image}
        desc={seo.description}
        username={twitter}
      />
    </>
  )
}

export default Seo

Seo.propTypes = {
  title: PropTypes.string,
  desc: PropTypes.string,
  defaultBanner: PropTypes.string,
  pathname: PropTypes.string,
  page: PropTypes.bool,
  //node: PropTypes.object,
  locale: PropTypes.string,
}

Seo.defaultProps = {
  title: null,
  desc: null,
  defaultBanner: null,
  pathname: null,
  page: false,
  //node: null,
  locale: "fr-fr",
}
