import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
// import gsap from "gsap"
import Seo from "../components/seo-helmet"
import { getRandomNumber, shuffle } from "../core/utils"
import useDeviceDetect from "../hooks/useDeviceDetect"
import Slider from "../components/ui/slick-slider"
import styled from "styled-components"

export const pageQuery = graphql`
  query ImagesPageQuery {
    prismicImages {
      _previewable
      data {
        meta_title {
          text
        }
        meta_description {
          text
        }
        meta_image {
          url
        }
        images {
          image {
            url
            alt
            dimensions {
              width
              height
            }
            gatsbyImageData(width: 2000)
          }
        }
      }
    }
  }
`

const Container = styled.div`
  .slick-arrow.slick-prev {
    display: none !important;
  }
  .gatsby-image-wrapper {
    box-shadow: 0px 0px 20px 12px rgb(80 80 80 / 50%);
    img {
      @media all and (min-width: 768px) {
        max-height: 80vh;
      }
    }
  }
  .images-scroller {
    scroll-behaviour: smooth;
    scroll-snap-type: x proximity;
    .images-scroller--slide {
      scroll-snap-align: start;
    }
  }
`

const Images = ({ data }) => {
  const { meta_title, meta_description, meta_image, images } =
    data.prismicImages.data
  // console.log(images)
  shuffle(images)
  const { isMobile } = useDeviceDetect()

  const _getRotation = () => {
    return isMobile ? 0 : getRandomNumber(-1, 1)
  }

  const _slides = images.map(({ image }, i) => (
    <div className="slide bg-black" key={i}>
      <div
        className="inner md:p-lg md:flex"
        style={{
          transform: `rotate(${_getRotation()}deg)`,
        }}
      >
        <GatsbyImage
          image={getImage(image)}
          alt={image.alt ? image.alt : ""}
          style={{
            maxWidth: isMobile ? "300vw" : "80vw",
            background: "black",
          }}
        />
      </div>
    </div>
  ))

  const _getAspectRatio = original => {
    return original.width / original.height
  }

  return (
    <div className="images overflow-hidden bg-black">
      <Seo
        pageTitle={meta_title.text}
        pageDescription={meta_description.text}
        pageBanner={meta_image.url}
        template="template-images"
        page={true}
      />
      <Container>
        <div className="images-slider h-screen hidden-sm cursor-pointer">
          <Slider>{_slides}</Slider>
        </div>
        <div className="images-scroller sm-only w-screen h-screen overflow-x-scroll">
          <div className="flex   ">
            {images.map(({ image }, i) => (
              <div
                className="flex-shrink-0 images-scroller--slide bg-black"
                key={i}
              >
                <GatsbyImage
                  image={getImage(image)}
                  alt={image.alt ? image.alt : ""}
                  style={{
                    width: `calc(var(--app-height) * ${_getAspectRatio(
                      image.dimensions
                    )})`,
                    height: `var(--app-height)`,
                    background: "black",
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Images
