import React from "react"
import styled from "styled-components"

const Container = styled.details`
  details > summary::-webkit-details-marker {
    display: none;
  }
  &[open] > summary {
    display: none;
  }
`

const OlbAgCredits = () => {
  return (
    <Container>
      <summary>©</summary>
      <p>
        Design{" "}
        <a
          href="https://olivierlebrun.fr"
          target="_blank"
          rel="noopener, noreferrer"
        >
          Olivier Lebrun
        </a>
        , Code{" "}
        <a
          href="https://ahmedghazi.com"
          target="_blank"
          rel="noopener, noreferrer"
        >
          Ahmed Ghazi
        </a>
      </p>
    </Container>
  )
}

export default OlbAgCredits
