import React from "react"
import { PrismicRichText } from "@prismicio/react"

const Files = ({ input }) => (
  <section className="files">
    <ul>
      {input.items.map((li, i) => (
        <li key={i}>
          <a
            href={li.file.url}
            target="_blank"
            rel="noopener, noreferrer"
            className="flex"
          >
            <div className="accr pr-md">NC</div>
            <div className="index pr-md">{li.index}</div>
            <div className="filename">{li.name}</div>
          </a>
        </li>
      ))}
    </ul>
    <div className="texte text-lg">
      <PrismicRichText field={input.primary.connoisseurs.richText} />
    </div>
  </section>
)

export default Files
