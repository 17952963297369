import React from "react"
import { graphql } from "gatsby"
import Seo from "../components/seo-helmet"
import { useSiteMetadata } from "../hooks/useSiteMetadata"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"
import Header from "../components/Header"
import Exhibitions from "../components/Exhibitions"
import Files from "../components/Files"
import OlbAgCredits from "../components/OlbAgCredits"
import Infos from "../components/Infos"

export const pageQuery = graphql`
  query HomePageQuery {
    prismicHome {
      _previewable
      data {
        meta_title {
          text
        }
        meta_description {
          text
        }
        meta_image {
          url
        }

        body {
          ... on PrismicHomeDataBodyHeader {
            slice_type
            items {
              label
              link {
                url
              }
            }
          }
          ... on PrismicHomeDataBodyExhibitions {
            slice_type
            items {
              start(formatString: "YYYY.MM")
              end(formatString: "YYYY.MM")
              texte {
                richText
              }
            }
          }
          ... on PrismicHomeDataBodyFiles {
            slice_type
            primary {
              connoisseurs {
                richText
              }
            }
            items {
              index
              file {
                url
              }
              name
            }
          }
          ... on PrismicHomeDataBodyInfos {
            slice_type
            primary {
              texte {
                richText
              }
            }
          }
        }
      }
    }
  }
`

const Home = ({ data: { prismicHome } }) => {
  // const { data } = prismicHome
  const { body } = prismicHome.data
  const { global } = useSiteMetadata()
  // console.log(body)
  const header = body.filter(el => el.slice_type === "header")[0]
  const exhibitions = body.filter(el => el.slice_type === "exhibitions")[0]
  const files = body.filter(el => el.slice_type === "files")[0]
  const infos = body.filter(el => el.slice_type === "infos")[0]

  // console.log(files)

  return (
    <div className="home p-md pb-xl md:pb-md">
      <Seo
        pageTitle={global.default_title.text}
        pageDescription={global.default_description.text}
        pageBanner={global.default_image.url}
        template="template-home"
        page={false}
      />
      <div className="flex flex-col md:flex-row">
        <div className="md:w-4/12 mb-md md:mb-0 md:pr-lg">
          <Header input={header} />

          <Exhibitions input={exhibitions} />
        </div>

        <div className="md:w-4/12 mb-md md:mb-0 md:pr-lg">
          <Files input={files} />
        </div>
        <div className="md:w-4/12 mb-md md:mb-0 ">
          <Infos input={infos} />
        </div>

        <div className="md:fixed left-0 bottom-0 md:p-md">
          <OlbAgCredits />
        </div>
      </div>
    </div>
  )
}

export default withPrismicPreview(Home)
