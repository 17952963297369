import React from "react"
import { PrismicRichText } from "@prismicio/react"

const Exhibitions = ({ input }) => (
  <section className="exhibitions">
    <ul>
      {input.items.map((li, i) => (
        <li key={i} className="flex mb-md">
          <div className="dates min-w-[100px]">
            <div className="start">{li.start}</div>
            <div className="start">{li.end}</div>
          </div>
          <div className="texte">
            <PrismicRichText field={li.texte.richText} />
          </div>
        </li>
      ))}
    </ul>
  </section>
)

export default Exhibitions
