import React from "react"

const Header = ({ input }) => {
  return (
    <header className="mb-md-">
      <ul className="flex">
        {input.items.map((li, i) => (
          <li key={i}>
            <a href={li.link.url} target="_blank" rel="noopener, noreferrer">
              {li.label}
            </a>
          </li>
        ))}
      </ul>
    </header>
  )
}

export default Header
