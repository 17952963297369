import React from "react"
import { PrismicRichText } from "@prismicio/react"

const Infos = ({ input }) => {
  return (
    <section className="infos">
      <div className="texte">
        <PrismicRichText field={input.primary.texte.richText} />
      </div>
    </section>
  )
}

export default Infos
