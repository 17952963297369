import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { navigate } from "gatsby"

import useDeviceDetect from "../hooks/useDeviceDetect"
import clsx from "clsx"

const Container = styled.div`
  .light {
    opacity: 0;
  }
  &.is-dark {
    .light {
      opacity: 1;
    }
    .dark {
      opacity: 0;
    }
  }
`
const Footer = ({ location }) => {
  const { isMobile } = useDeviceDetect()
  const [visual, setVisual] = useState()
  const [dark, setDark] = useState(false)
  const [easterEgg, setEasterEgg] = useState("")

  const theme = {
    bench: {
      name: "bench",
      dark: "./nc-bench-dark.png",
      light: "./nc-bench-light.png",
    },
    stool: {
      name: "stool",
      dark: "./nc-stool-dark.png",
      light: "./nc-stool-light.png",
    },
    egg: {
      name: "egg",
      dark: "./nc-egg-dark.png",
      light: "./nc-egg-light.png",
    },
    jubilee: {
      name: "jubilee",
      dark: "./nc-jubilee-dark.png",
      light: "./nc-jubilee-light.png",
    },
    twingo: {
      name: "twingo",
      dark: "./nc-twingo-dark.png",
      light: "./nc-twingo-light.png",
    },
  }
  const stoolOrBench = isMobile ? theme.stool : theme.bench
  // console.log(theme)

  useEffect(() => {
    document.addEventListener("keyup", _onKeyBoard)

    return () => {
      document.removeEventListener("keyup", _onKeyBoard)
    }
  }, [easterEgg, stoolOrBench, setVisual])

  const _onKeyBoard = e => {
    console.log(e.key)
    setEasterEgg(e.key !== easterEgg ? e.key : "")
    // if (e.key === "e") {
    //   // setEasterEgg(!easterEgg)
    // }
  }
  useEffect(() => {
    // setVisual(easterEgg ? theme.egg : stoolOrBench)
    console.log(easterEgg)
    if (easterEgg === "") {
      setVisual(stoolOrBench)
      return
    }
    switch (easterEgg) {
      case "e":
        setVisual(theme.egg)
        break
      case "t":
        setVisual(theme.twingo)
        break
      case "j":
        setVisual(theme.jubilee)
        break
      default:
        setVisual(stoolOrBench)

        break
    }
  }, [easterEgg])

  useEffect(() => {
    setDark(location.pathname !== "/")
  }, [location])

  useEffect(() => {
    // console.log("isMobile", isMobile)
    setVisual(stoolOrBench)
  }, [isMobile])

  useEffect(() => {
    // setVisual(stoolOrBench)
  }, [dark])

  const goToImages = () => {
    const target = location.pathname === "/" ? "/images" : "/"
    navigate(target)
  }

  console.log(visual)
  // if (visual) console.log(visual.light)
  return (
    <footer className="z-50 fixed bottom-0 right-0 p-md">
      <Container
        onClick={() => goToImages()}
        role="button"
        tabIndex="0"
        className={clsx(
          "w-[55vw] md:w-[45vw] cursor-pointer",
          dark ? "is-dark" : ""
        )}
      >
        {visual && (
          <>
            <img
              src={visual.light}
              className="absolute bottom-0 w-full transition-opacity light"
              alt="visual light"
            />
            <img
              src={visual.dark}
              className="absolute bottom-0 w-full transition-opacity dark"
              alt="visual dark"
            />
          </>
        )}
      </Container>
    </footer>
  )
}

export default Footer
