import { useStaticQuery, graphql } from "gatsby"

export const useSiteMetadata = () => {
  const { site, global } = useStaticQuery(
    graphql`
      query SiteMetaData {
        site {
          buildTime(formatString: "YYYY-MM-DD")
          siteMetadata {
            siteTitle
            siteDescription
            siteUrl
            defaultBanner
            ogLanguage
            author
            twitter
            facebook
          }
        }
        global: prismicSettings {
          data {
            default_title {
              text
            }
            default_description {
              text
            }
            default_image {
              url
            }
          }
        }
      }
    `
  )
  // console.log(global)
  return {
    buildTime: site.buildTime,
    site: site.siteMetadata,
    global: global.data,
  }
}
