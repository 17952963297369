import React, { useEffect } from "react"
// import { useStaticQuery, graphql } from "gatsby";
// import Header from "./Header"
import Footer from "./Footer"

const Layout = ({ location, children }) => {
  useEffect(() => {
    _format()
    window.addEventListener("resize", _format)
    // if (window.innerWidth < 1080 && "ontouchstart" in window) setIsMobile(true)

    return () => {
      window.removeEventListener("resize", _format)
    }
  }, [])

  const _format = () => {
    const wh = window.innerHeight
    // const header = document.querySelector("header")
    document.documentElement.style.setProperty("--app-height", wh + "px")
  }

  return (
    <div id="page">
      {/* <Header /> */}
      <main className="z-10 bg-white">{children}</main>
      <Footer location={location} />

      {/* <ScreenSaverImage /> */}
    </div>
  )
}

export default Layout
